import React from 'react';
import Layout from '../components/layout';
import { graphql } from 'gatsby';

const notes = (props) => {
  const notesMarkdown = props.data.markdownRemark;
  console.log(notesMarkdown);

  // Destructure all the frontmatter data.
  const { title,
          description,
          path,
          publishedDate,
          updatedDate
        } = notesMarkdown.frontmatter;
  const editPath = "https://github.com/timdoubleu/portfolio/blob/master/src/markdown"+path;

  return (
    <Layout>
      <h1>{title}</h1>
      <p>{description}</p>
      <p>Published on: {publishedDate}, last updated on: {updatedDate}</p>
      <p> Find an error? Think I'm a big dummy and wrote something stupid?
        <a href={editPath}> Edit this post on Github! </a>
      </p>

      <div dangerouslySetInnerHTML={{__html: notesMarkdown.html}}></div>
    </Layout>
  );
}

export default notes;

export const query = graphql`
  query NotesQuery($slug: String!) {
    markdownRemark(fields: { slug: {eq: $slug } }) {
      html
      frontmatter {
        title
        description
        path
        publishedDate
        updatedDate
        tags
      }
    }
  }
`;
